import Box from 'assets/components/box/Box';
import Button from 'assets/components/button/Button';
import LinkButton from 'assets/components/button/LinkButton';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import ThemeButtonFloating from 'assets/components/button/themes/Theme.Button.Floating';
import Collection from 'assets/components/data/Collection';
import HeaderData from 'assets/components/data/rows/HeaderData';
import HeaderRow from 'assets/components/data/rows/HeaderRow';
import Row from 'assets/components/data/rows/Row';
import RowActions from 'assets/components/data/rows/RowActions';
import RowData from 'assets/components/data/rows/RowData';
import RowImage from 'assets/components/data/rows/RowImage';
import ThemeCollectionList from 'assets/components/data/themes/Theme.Collection.List';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageError from 'assets/components/page/themes/Theme.Page.Error';
import ThemePageList from 'assets/components/page/themes/Theme.Page.List';
import Pagination from 'assets/components/pagination/Pagination';
import ThemePaginationRounded from 'assets/components/pagination/themes/Theme.Pagination.Rounded';
import ThemeTagRounded from 'assets/components/tag/themes/Theme.Tag.Rounded';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { date, defaultDateFormat, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { combineStrings } from 'assets/utils/data/String';
import { isEmpty } from 'lodash';
import ContractStatusCode from 'models/enums/ContractStatusCode';
import contractStatusFlowApi from 'models/sales/contract/statusFlow/Api.Contract.StatusFlow';
import { isMobile } from 'react-device-detect';
import { SalesNewContractAction } from '../../newContract/Sales.NewContract.Hooks';
import { salesNewContractIndexRoute } from '../../newContract/Sales.NewContract.Index';
import { SalesContractAction, useSalesContractProvider } from '../Sales.Contract.Hooks';
import ContractStatusStateMachine from '../other/Contract.StatusStateMachine';
import SalesContractFilters from './Sales.Contract.Filters';
import FontNestedSizeVariable from 'assets/themes/variables/FontNestedSizeVariable';

export default function ContractList() {
  const { lang } = getLocales();
  const { payload: statusInfo } = useApi({ action: contractStatusFlowApi });
  const { listApi, pageRouter, permissions, exportContractsToExcel } = useSalesContractProvider();
  const { redirect: redirectToNewContract } = usePageRouter<
    Module.Sales.NewContract.Params,
    Module.Sales.NewContract.Query
  >({ route: salesNewContractIndexRoute });

  return !listApi.payload && !listApi.isExecuting && listApi.apiCounter > 0 ? (
    <Page
      class={ThemePageError}
      sidebar={<Icon class="fas-clipboard-list" />}
      header={lang.contract}
      render={lang.noInfo}
    />
  ) : (
    <Page
      class={ThemePageList}
      isLoading={listApi.isExecuting}
      header={
        <>
          <b style={{ marginRight: 'auto' }} children={lang.contract} />
          <SalesContractFilters />
          &nbsp;
          <Button
            class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
            active
            media={<Icon class="fas-plus" />}
            render={!isMobile && lang.addNew}
            onClick={redirectToNewContract}
            disabled={!permissions.CREATE_CONTRACT}
            data={{ action: SalesNewContractAction.VEHICLE }}
          />
        </>
      }
      subHeader={
        <>
          <Button
            class={isMobile ? ThemeButtonFloating : ThemeButtonCircle}
            active
            media={<Icon class="fas-download" />}
            render={!isMobile && lang.export}
            onClick={exportContractsToExcel}
            disabled={!permissions.CREATE_CONTRACT || isEmpty(listApi?.payload?.data)}
          />
        </>
      }
      render={
        <Collection class={ThemeCollectionList}>
          <HeaderRow>
            <HeaderData>{lang.appNumber}</HeaderData>
            <HeaderData>
              {lang.vehicle}/{lang.vin}
            </HeaderData>
            <HeaderData children={lang.client} />
            <HeaderData children={lang.dealer} />
            <HeaderData children={lang.createdAt} />
            <HeaderData children={lang.expiryDate} />
            <HeaderData numOfActions={1} />
          </HeaderRow>
          <DataMap
            data={listApi.payload?.data}
            render={({ data: contract }) => (
              <Row
                key={contract.id}
                children={
                  <>
                    <RowImage render={<Icon class="fas-file-contract" />} />
                    <RowData
                      info={contract.statusCode?.replace('_', ' - ')}
                      title={contract.appNumber + (contract.revisionNumber ? `-R${contract.revisionNumber}` : '')}
                      subtitle={
                        <>
                          {contract?.isPayed && (
                            <Box class={ThemeTagRounded} style={{ backgroundColor: Theme.Color.success }}>
                              <Icon class="fas-check" />
                              &nbsp; Paid
                            </Box>
                          )}
                          {(contract?.isSigned || contract?.isSentForSigning) && (
                            <Box class={ThemeTagRounded} style={{ backgroundColor: Theme.Color.success }}>
                              &nbsp;
                              <Icon class="fas-check" />
                              {contract?.isSigned ? 'Signed' : 'Sent for signing'}
                            </Box>
                          )}
                        </>
                      }
                    />
                    <RowData
                      title={
                        <div style={{ fontSize: `${FontNestedSizeVariable.M}` }}>
                          <div>{contract.vehicle.year?.toString()}</div>
                          <div>
                            {contract.vehicle.makeCode} {contract.vehicle.model}
                          </div>
                        </div>
                      }
                      info={contract.vehicle?.vin}
                    />
                    <RowData>{combineStrings(' ', contract.client?.firstName, contract.client?.lastName)}</RowData>
                    <RowData>{contract.dealer?.name}</RowData>
                    <RowData>
                      <span>
                        {date(contract?.createdAtUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat)}
                      </span>
                      <span>
                        by{' '}
                        {combineStrings(' ', contract.createdBy?.firstName, contract.createdBy?.lastName) ||
                          lang.unknownUser}
                      </span>
                    </RowData>
                    <RowData>
                      {date(contract?.expiryDate, defaultServerDateTimeFormat).format(defaultDateFormat)}
                    </RowData>
                    <RowActions isVertical>
                      <ContractStatusStateMachine
                        statusInfo={statusInfo}
                        contractId={contract.id}
                        currentStatus={contract.statusCode ?? ContractStatusCode.OPEN_SUBMITTED}
                        onChange={() => listApi.execute((b) => b)}
                      />
                      <Button
                        class={ThemeButtonCircle}
                        media={<Icon class="fas-info-circle" />}
                        onClick={pageRouter.updateParams}
                        data={{ id: contract.id.toString(), action: SalesContractAction.INFO }}
                        htmlElementProps={{ title: lang.info }}
                      />
                      {contract?.documentationAggregate && (
                        <LinkButton
                          class={ThemeButtonCircle}
                          url={contract.documentationAggregate.url}
                          media={<Icon class="fas-download" />}
                          target="_blank"
                          htmlElementProps={{ title: lang.download }}
                        />
                      )}
                      <Button
                        class={ThemeButtonCircle}
                        media={<Icon class="fas-edit" />}
                        onClick={redirectToNewContract}
                        disabled={
                          ContractStatusCode.OPEN_SUBMITTED !== contract?.statusCode || !permissions.EDIT_CONTRACT
                        }
                        data={{ action: SalesNewContractAction.VEHICLE, id: contract.id.toString() }}
                        htmlElementProps={{ title: lang.edit }}
                      />
                    </RowActions>
                  </>
                }
              />
            )}
          />
        </Collection>
      }
      footer={
        <Pagination
          class={ThemePaginationRounded}
          count={listApi.payload?.count}
          offset={listApi.body?.offset}
          limit={listApi.body?.limit}
          onLimitChange={(limit) => listApi.execute((body) => ({ ...body, limit, offset: 0 }))}
          onOffsetChange={(offset) => listApi.execute((body) => ({ ...body, offset }))}
        />
      }
    />
  );
}
