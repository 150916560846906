import DataMap from 'assets/components/dataMap/DataMap';
import TextBox, { textBoxStyles } from 'assets/components/textBox/TextBox';
import { defaultLanguage, getLanguage, getLocales } from 'assets/locales/Locale';
import { paragraphStyles } from '../claimEditInfo/ClaimEdit.Info';
import { useClaimJobEdit } from './ClaimEdit.Job.Hooks';

export default function ClaimEditJobInfo() {
  const { lang } = getLocales();
  const currentLanguage = getLanguage();
  const { claimTypeReport, report, claimJob } = useClaimJobEdit();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {!claimTypeReport && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextBox
            class={textBoxStyles.block.flexBlock}
            title={`${lang.customerComplaint}:`}
            subtitle={claimJob.customerComplaint ?? 'N/A'}
          />
          <TextBox
            class={textBoxStyles.block.flexBlock}
            title={`${lang.causeOfFailure}:`}
            subtitle={claimJob.cause ?? 'N/A'}
          />
          <TextBox
            class={textBoxStyles.block.flexBlock}
            title={`${lang.correctionDetails}:`}
            subtitle={claimJob.correction ?? 'N/A'}
          />
        </div>
      )}
      {!!claimTypeReport && (
        <div style={paragraphStyles}>
          <DataMap
            data={claimTypeReport?.infoInputs}
            render={({ data: input }) => (
              <TextBox
                key={input.key}
                class={textBoxStyles.block.flexBlock}
                title={`${input.name[currentLanguage] || input.name[defaultLanguage]}:`}
                subtitle={report[input.key] ?? 'N/A'}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}
