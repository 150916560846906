import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import Icon from 'assets/components/icon/Icon';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import Modal from 'assets/components/page/templates/Modal';
import ThemeModalDefault from 'assets/components/page/templates/themes/Theme.Modal.Default';
import { defaultLanguage, getLanguage, getLocales } from 'assets/locales/Locale';
import { defaultInputDateTimeFormat } from 'assets/utils/data/Date';
import { first } from 'lodash';
import { ReportInputType } from 'models/claims/claimTypeReportSettings/Model.ClaimTypeReportSettings';
import { useEffect } from 'react';
import { useClaimJobEdit } from './ClaimEdit.Job.Hooks';
import ClaimEditJobIndemityCalc from './ClaimEdit.Job.IndemityCalc';
import ClaimEditJobSubmitButton from './ClaimEdit.Job.SubmitButton';

const Inputs = {
  [ReportInputType.text]: TextInput,
  [ReportInputType.number]: NumberInput,
  [ReportInputType.multilineText]: MultilineTextInput,
  [ReportInputType.date]: (props: Component.Input.DateInput.Import<string>) => (
    <DateInput {...props} dateTimeFormat={defaultInputDateTimeFormat} />
  ),
};
export default function ClaimEditJobForm() {
  const { lang } = getLocales();
  const currentLanguage = getLanguage();
  const { form, claimTypeReport, report, claimTypes, setAction } = useClaimJobEdit();
  const job = form.data;

  const onlyClaimType = claimTypes && claimTypes.length === 1;

  function updateReport(data: any) {
    report.update(data);
    if (job?.claimJobReports && claimTypeReport) {
      job.claimJobReports = [
        {
          productReport: report.toJSON(),
          claimTypeReportSettingId: claimTypeReport?.id,
        },
      ];
    }
    form.update({ ...form.data });
  }

  useEffect(() => {
    if (onlyClaimType) {
      form.update({ claimType: first(claimTypes) });
    }
  }, []);

  const claimTypeValue = onlyClaimType ? first(claimTypes).id : form.data?.claimType?.id;

  return (
    <Modal
      subcomponents={ThemeModalDefault}
      header={lang.edit}
      onClose={() => setAction('expanded')}
      render={
        <FormWrapper>
          {onlyClaimType ? null : (
            <SelectInput
              name="claimType"
              label={lang.claimType}
              error={form.errors?.claimType?.id}
              data={claimTypes?.map((it) => it.displayInfo)}
              value={claimTypeValue}
              onChange={({ claimType: id }) => {
                form.update({ claimType: { id } });
              }}
              icon={<Icon class="fas-clipboard-list" />}
            />
          )}

          {!form.data.claimType ? null : !claimTypeReport ? (
            <>
              <MultilineTextInput
                name="customerComplaint"
                label={lang.customerComplaint}
                value={form.data?.customerComplaint}
                onChange={form.update}
              />
              <MultilineTextInput
                name="cause"
                label={lang.causeOfFailure}
                value={form.data?.cause}
                onChange={form.update}
              />
              <MultilineTextInput
                name="correction"
                label={lang.correctionDetails}
                value={form.data?.correction}
                onChange={form.update}
              />
            </>
          ) : (
            <>
              <DataMap
                data={claimTypeReport?.infoInputs}
                render={({ data: input }) => {
                  const FinalInput = Inputs[input.type];
                  return !FinalInput ? null : (
                    <FinalInput
                      key={input.key}
                      label={input.name[currentLanguage] || input.name[defaultLanguage]}
                      value={report[input.key] as never}
                      name={input.key}
                      onChange={updateReport}
                    />
                  );
                }}
              />
              <ClaimEditJobIndemityCalc />
            </>
          )}
        </FormWrapper>
      }
      footer={
        <>
          <Button onClick={setAction} data="expanded" media="fas-ban" render={lang.cancel} class={ThemeButtonCircle} />
          <ClaimEditJobSubmitButton />
        </>
      }
    />
  );
}
