import { getLocales } from 'assets/locales/Locale';
import User from 'models/core/user/Model.User';
import Model from 'models/Model';
import ClaimDocument from '../claimDocument/ClaimDocument';
import ClaimJob from '../claimJob/Model.ClaimJob';
import ClaimType from '../claimType/Model.ClaimType';
import Contract from '../../sales/contract/Model.Contract';
import Payee from '../payee/Model.Payee';
import claimChangeOwnershipApi from './changeOwnership/Api.Claim.ChangeOwnership';
import claimDeleteApi from './delete/Api.Claim.Delete';
import claimFindApi from './find/Api.Claim.Find';
import claimListApi from './list/Api.Claim.List';
import claimSaveApi from './save/Api.Claim.Save';
import claimSavePublic from './savePublic/Api.Claim.SavePublic';
import claimSetStatusApi from './setStatus/Api.Claim.SetStatus';
import claimStatusFlowApi from './statusFlow/Api.Claim.StatusFlow';
import claimStatusRequirementsApi from './statusRequirements/Api.Claim.StatusRequirements';
import claimTakeOwnershipApi from './takeOwnership/Api.Claim.TakeOwnership';
import { HttpStatus } from 'config/Api.Config';
import ClaimInvoice from '../claimInvoice/ClaimInvoice';
import ClaimEmail from '../claimEmail/ClaimEmail';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';

export default class Claim extends Model<Model.IClaim>({ icon: 'fas-cloud' }) implements Model.Instance<Model.IClaim> {
  id: number;
  number?: string;
  authorizationNumber?: string;
  source?: string;
  statusCode?: Model.Enum.ClaimStatusCode;
  workOrderNumber?: string;
  submitedDateUtc: string;
  createdAtUtc?: string;
  modifiedAtUtc?: string;
  openedDateUtc?: string;
  decidedDateUtc?: string;
  paymentDateUtc?: string;
  openedAdjudicationDate?: string;
  dateOfLoss?: string;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  odometerReading: number;
  plateNumber?: string;
  descriptionOfDamage?: string;
  claimJobCount?: number;

  streetAddress?: string;
  postalCode?: string;
  city?: string;
  provinceCode?: string;
  countryCode?: string;

  contractId: number; //TODO REMOVE
  reporterId: number; //TODO REMOVE
  ownerId: number; //TODO REMOVE

  claimType?: ClaimType;
  contract?: Contract;
  owner?: User;
  reporter?: User;
  createdByPayee?: Payee;
  createdByPayeeId?: number;

  claimJobs?: ClaimJob[];
  claimDocuments?: ClaimDocument[];
  claimInvoices?: ClaimInvoice[];
  claimEmails?: ClaimEmail[];

  // Public claim
  publicContactName?: string;
  publicContactPhone?: string;
  publicContactEmail?: string;

  dealerId?: number;
  dealer?: Dealer;

  constructor(data: Partial<Model.IClaim> | Utils.FormData<Model.IClaim>, language?: Locale.Languages) {
    super(
      {
        claimType: ClaimType,
        contract: Contract,
        owner: User,
        reporter: User,
        createdByPayee: Payee,
        dealer: Dealer,
        claimJobs: [ClaimJob],
        claimDocuments: [ClaimDocument],
        claimInvoices: [ClaimInvoice],
        claimEmails: [ClaimEmail],
      },
      language
    );
    this.update(data);
  }
  get displayInfo(): Model.DisplayInfo {
    const { lang } = getLocales();
    return {
      id: this.id,
      title:
        `${this?.contract?.appNumber}/${this?.contract?.client?.displayInfo?.title}/${this?.contract?.vehicle?.vin}` ||
        this.id.toString(),
      info: `#${this.id} - ${this.statusCode.replace(/_/g, ' - ') || lang.noStatusDefined}`,
      subtitle: this.contactName,
    };
  }

  static async list(body?: Api.Core.Claim.List.IRequest) {
    const { payload, ...rest } = await claimListApi(body);
    return {
      ...rest,
      payload: payload && {
        data: payload.data?.map((it) => new Claim(it)),
        count: payload.count,
      },
    };
  }
  static async find(params: { id: number }) {
    const { payload, ...rest } = await claimFindApi(params);
    return {
      ...rest,
      payload: new Claim(payload),
    };
  }
  async save() {
    return await claimSaveApi(this);
  }
  async savePublic() {
    return await claimSavePublic({
      body: this,
      params: {
        clientName: this.contract?.client?.lastName,
        vin: this.contract?.vehicle?.vin,
        contractNumber: this.contract?.appNumber,
      },
    });
  }
  async delete() {
    return await claimDeleteApi({ id: this.id });
  }
  static async setStatus(body: Api.Core.Claim.SetStatus.IRequest) {
    return await claimSetStatusApi(body);
  }
  static async changeOwnership(body: Api.Core.Claim.ChangeOwnership.IRequest) {
    return await claimChangeOwnershipApi(body);
  }
  static async takeOwnership(body: Api.Core.Claim.TakeOwnership.IRequest) {
    return await claimTakeOwnershipApi(body);
  }
  static async getStatusFlowAndRequirements(): Promise<Api.Res<Model.ClaimStatusInfo>> {
    const { payload: requirements } = await claimStatusRequirementsApi();
    const { payload: flow } = await claimStatusFlowApi();
    return {
      status: HttpStatus.ok,
      payload: Object.keys(requirements).reduce(
        (data, status) => ({
          ...data,
          [status]: {
            permissions: requirements[status].ownershipRequirements.permissions,
            nextStatusList: flow[status],
          },
        }),
        {}
      ),
    };
  }
}
