import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Icon from 'assets/components/icon/Icon';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import Modal from 'assets/components/page/templates/Modal';
import ThemeModalDefault from 'assets/components/page/templates/themes/Theme.Modal.Default';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import Contract from 'models/sales/contract/Model.Contract';
import ClaimDocumentForm from 'module/public-web/views/claimSubmit/Claim.DocumentForm';
import ClaimDocumentPreview from '../other/Claim.DocumentPreview';
import useClaimSimpleClaim from './Claim.SimpleClaim.Hooks';

export default function ClaimSimpleClaimForm(props: { contract: Contract; onClose: () => void }) {
  const { lang } = getLocales();
  const { form, claimTypes, onlyClaimType, claimTypeValue } = useClaimSimpleClaim(props);

  return (
    <Modal
      subcomponents={ThemeModalDefault}
      header={lang.createSimpleClaim}
      onClose={props.onClose}
      render={
        <FormWrapper>
          {!onlyClaimType && (
            <SelectInput
              name="claimType"
              label={lang.claimType}
              data={claimTypes?.map((it) => it.displayInfo)}
              value={claimTypeValue}
              onChange={({ claimType: id }) => {
                form.update({ claimType: { id } });
              }}
              icon={<Icon class="fas-clipboard-list" />}
            />
          )}
          <MultilineTextInput
            name="customerComplaint"
            label={lang.customerComplaint}
            value={form.data?.customerComplaint}
            onChange={form.update}
          />
          <MultilineTextInput
            name="cause"
            label={lang.causeOfFailure}
            value={form.data?.cause}
            onChange={form.update}
          />
          <MultilineTextInput
            name="correction"
            label={lang.correctionDetails}
            value={form.data?.correction}
            onChange={form.update}
          />
          <h2 style={{ display: 'flex', alignItems: 'center' }}>
            {lang.documents}
            <Button
              class={ThemeButtonCircle}
              style={{ marginLeft: 'auto', fontSize: Theme.FontSize.M }}
              render={form.data.isAddingNewDocument ? lang.cancel : lang.add}
              media={<Icon class={form.data.isAddingNewDocument ? 'fas-ban' : 'fas-plus'} />}
              data={{ isAddingNewDocument: !form.data.isAddingNewDocument }}
              onClick={form.update}
              active={form.data.isAddingNewDocument}
              htmlElementProps={{ title: form.data.isAddingNewDocument ? lang.ban : lang.add }}
            />
          </h2>
          <ClaimDocumentPreview
            documents={[
              ...form.data.claimDocuments.map((it) => ({
                id: it.id,
                url: it.mediaFile?.url,
                name: it?.name,
                extension: it.mediaFile?.extension,
                description: it?.notes,
                info: lang.new,
              })),
            ]}
          />
          <br />
          {form.data.isAddingNewDocument && (
            <ClaimDocumentForm
              onSubmit={(document) => form.update({ claimDocuments: [...form.data.claimDocuments, document] })}
            />
          )}
        </FormWrapper>
      }
      footer={
        <>
          <Button
            onClick={form.submit}
            media={<Icon class="fas-save" />}
            render={lang.save}
            active={!form.hasErrors && form.isUpdated}
            disabled={form.hasErrors || !form.isUpdated}
            isLoading={form.isSubmitted}
            class={ThemeButtonCircle}
            htmlElementProps={{ title: lang.save }}
          />
        </>
      }
    />
  );
}
