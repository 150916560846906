import Button from 'assets/components/button/Button';
import ThemeButtonMenuItem from 'assets/components/button/themes/Theme.Button.MenuItem';
import FilterForm from 'assets/components/filters/filterForm/FilterForm';
import ThemeFilterFormDrawer from 'assets/components/filters/filterForm/themes/Theme.FilterForm.Drawer';
import Icon from 'assets/components/icon/Icon';
import DateInput from 'assets/components/inputs/dateInput/DateInput';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import DropdownButton from 'assets/templates/dropdownButton/DropdownButton';
import ThemeDropdownButtonPopup from 'assets/templates/dropdownButton/themes/Theme.DropdownButton.Popup';
import { defaultInputDateTimeFormat } from 'assets/utils/data/Date';
import { OrderType } from 'config/Api.Config';
import { compact, flatten, isEmpty, uniqBy } from 'lodash';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import VehicleModel from 'models/productManagement/vehicleModel/Model.VehicleModel';
import { useSalesContractProvider } from '../Sales.Contract.Hooks';

export default function SalesContractFilters() {
  const { lang } = getLocales();
  const { user } = useAuthStore();
  const { listApi, statuses } = useSalesContractProvider();

  const dealersApi = useApi({
    action: Dealer.list,
    wait: true,
    body: { id: user?.dealers?.map((d) => d.id) ?? undefined },
  });
  const modelsApi = useApi({
    action: VehicleModel.list,
    wait: true,
  });

  return (
    <>
      <DropdownButton
        buttonMedia={<Icon class="fas-sort" />}
        horizontalPosition="auto_fixed"
        verticalPosition="auto_fixed"
        htmlElementProps={{ title: lang.sort }}
        subcomponents={ThemeDropdownButtonPopup}
        dropdownOptions={
          <>
            <Button
              class={ThemeButtonMenuItem}
              render={lang.firstModified}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'modifiedAtUtc',
                orderType: OrderType.asc,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.lastModified}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'modifiedAtUtc',
                orderType: OrderType.desc,
                thenBy: undefined,
                thenType: undefined,
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.ascendingByName}
              media={<Icon class="fas-caret-up" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'appNumber',
                orderType: OrderType.asc,
                thenBy: ['client.FirstName', 'client.LastName', 'vehicle.Vin'],
                thenType: [OrderType.asc, OrderType.asc, OrderType.asc],
              })}
            />
            <Button
              class={ThemeButtonMenuItem}
              render={lang.descendingByName}
              media={<Icon class="fas-caret-down" />}
              onClick={listApi.execute}
              data={(currentFilters) => ({
                ...currentFilters,
                orderBy: 'appNumber',
                orderType: OrderType.desc,
                thenBy: ['client.FirstName', 'client.LastName', 'vehicle.Vin'],
                thenType: [OrderType.desc, OrderType.desc, OrderType.desc],
              })}
            />
          </>
        }
      />
      &nbsp;
      <FilterForm
        subcomponents={ThemeFilterFormDrawer}
        filters={listApi.body}
        onFilter={(newFilters) => listApi.execute(newFilters)}
        onReset={listApi.reset}
        onOpen={() => {
          if (!dealersApi.payload) dealersApi.execute((body) => body);
          if (!modelsApi.payload) modelsApi.execute((body) => body);
        }}
        render={({ tempFilters, setFilters }) => {
          const dealers = dealersApi?.payload?.data ?? [];

          const products = !isEmpty(tempFilters?.dealerId)
            ? flatten(dealers.filter((d) => tempFilters?.dealerId?.some((id) => id === d.id)).map((d) => d.products))
            : uniqBy(flatten(dealers.map((d) => d.products)), (p) => p.id);

          const makes =
            compact(
              uniqBy(
                modelsApi?.payload?.data?.map((m) => m.vehicleMake),
                (m) => m.id
              )
            ) ?? [];

          const models =
            modelsApi?.payload?.data?.filter((m) =>
              tempFilters?.makeCode?.some((code) => code === m.vehicleMake?.makeCode)
            ) ?? [];

          return (
            <>
              <TextInput
                name="contractNumber"
                label={lang.contractNumber}
                value={tempFilters?.contractNumber}
                onChange={setFilters}
                icon={<Icon class="if-quill-pen" />}
              />
              <SelectInput
                name="statusCode"
                label={lang.status}
                data={statuses.map((item) => ({
                  id: item.id,
                  title: item.title,
                  icon: <Icon class="fas-check" />,
                }))}
                isMultiselect
                value={tempFilters?.statusCode}
                onChange={({ statusCode }) => setFilters({ ...tempFilters, statusCode })}
                icon={<Icon class="fas-cloud" />}
              />
              <TextInput
                name="vin"
                label={lang.vin}
                value={tempFilters?.vin}
                onChange={setFilters}
                icon={<Icon class="if-quill-pen" />}
              />
              <NumberInput
                name="vehicleYear"
                label={lang.vehicleYear}
                value={tempFilters?.vehicleYear}
                onChange={setFilters}
                icon={<Icon class="if-quill-pen" />}
              />
              <SelectInput
                name="makeCode"
                label={lang.vehicleMake}
                data={makes.map((item) => ({
                  id: item.makeCode,
                  title: item.makeCode,
                  icon: <Icon class="fas-cloud" />,
                }))}
                isMultiselect
                value={tempFilters?.makeCode}
                disabled={!makes.length}
                onChange={({ makeCode }) => setFilters({ ...tempFilters, makeCode })}
                icon={<Icon class="fas-cloud" />}
              />
              <SelectInput
                name="vehicleModelId"
                label={lang.vehicleModel}
                data={models.map((item) => ({
                  ...item.displayInfo,
                  icon: <Icon class="fas-cloud" />,
                }))}
                isMultiselect
                value={tempFilters?.vehicleModelId}
                disabled={!models.length}
                onChange={({ vehicleModelId }) => setFilters({ ...tempFilters, vehicleModelId })}
                icon={<Icon class="fas-cloud" />}
              />
              <TextInput
                name="clientName"
                label={lang.clientName}
                value={tempFilters?.clientName}
                onChange={setFilters}
                icon={<Icon class="if-quill-pen" />}
              />
              <SelectInput
                name="dealerId"
                label={lang.dealers}
                data={dealers.map((item) => ({
                  ...item.displayInfo,
                  icon: <Icon class="fas-cloud" />,
                }))}
                isMultiselect
                value={tempFilters?.dealerId}
                disabled={!dealers.length}
                onChange={({ dealerId }) => setFilters({ ...tempFilters, dealerId })}
                icon={<Icon class="fas-cloud" />}
              />
              <SelectInput
                name="productId"
                label={lang.products}
                data={products.map((item) => ({
                  ...item.displayInfo,
                  icon: <Icon class="fas-box" />,
                }))}
                isMultiselect
                value={tempFilters?.productId}
                onChange={({ productId }) => setFilters({ ...tempFilters, productId })}
                icon={<Icon class="fas-box" />}
              />
              <InputWrapper>
                <DateInput
                  name="expiryFromDateUtc"
                  label={lang.expiryFromDate}
                  dateTimeFormat={defaultInputDateTimeFormat}
                  value={tempFilters?.expiryFromDateUtc}
                  onChange={({ expiryFromDateUtc }) => setFilters({ ...tempFilters, expiryFromDateUtc })}
                  icon={<Icon class="fas-calendar-alt" />}
                />
                <DateInput
                  name="expiryToDateUtc"
                  label={lang.expiryToDate}
                  dateTimeFormat={defaultInputDateTimeFormat}
                  value={tempFilters?.expiryToDateUtc}
                  onChange={({ expiryToDateUtc }) => setFilters({ ...tempFilters, expiryToDateUtc })}
                  icon={<Icon class="fas-calendar-alt" />}
                />
              </InputWrapper>
              <InputWrapper>
                <DateInput
                  name="effectiveFromDateUtc"
                  label={lang.effectiveFromDate}
                  dateTimeFormat={defaultInputDateTimeFormat}
                  value={tempFilters?.effectiveFromDateUtc}
                  onChange={({ effectiveFromDateUtc }) => setFilters({ ...tempFilters, effectiveFromDateUtc })}
                  icon={<Icon class="fas-calendar-alt" />}
                />
                <DateInput
                  name="effectiveToDateUtc"
                  label={lang.effectiveToDate}
                  dateTimeFormat={defaultInputDateTimeFormat}
                  value={tempFilters?.effectiveToDateUtc}
                  onChange={({ effectiveToDateUtc }) => setFilters({ ...tempFilters, effectiveToDateUtc })}
                  icon={<Icon class="fas-calendar-alt" />}
                />
              </InputWrapper>
              <InputWrapper>
                <DateInput
                  name="createdAtFromDateUtc"
                  label={lang.createdAtFromDate}
                  dateTimeFormat={defaultInputDateTimeFormat}
                  value={tempFilters?.createdAtFromDateUtc}
                  onChange={({ createdAtFromDateUtc }) => setFilters({ ...tempFilters, createdAtFromDateUtc })}
                  icon={<Icon class="fas-calendar-alt" />}
                />
                <DateInput
                  name="createdAtToDateUtc"
                  label={lang.createdAtToDate}
                  dateTimeFormat={defaultInputDateTimeFormat}
                  value={tempFilters?.createdAtToDateUtc}
                  onChange={({ createdAtToDateUtc }) => setFilters({ ...tempFilters, createdAtToDateUtc })}
                  icon={<Icon class="fas-calendar-alt" />}
                />
              </InputWrapper>
            </>
          );
        }}
      />
    </>
  );
}
