import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Icon from 'assets/components/icon/Icon';
import NumberInput from 'assets/components/inputs/number/NumberInput';
import NumberInputTextValue from 'assets/components/inputs/number/NumberInputTextValue';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import ClaimJobItemPayee from 'models/claims/claimJobItemPayee/Model.ClaimJobItemPayee';
import CountryCode from 'models/enums/CountryCode';
import LineItem from 'models/enums/LineItem';
import PaymentStatus from 'models/enums/PaymentStatus';
import { CSSProperties } from 'react';
import { useClaimEditJobPayeeItem, useClaimJobEdit } from '../ClaimEdit.Job.Hooks';

type Props = {
  lossCodes: Model.ILossCode[];
  index: number;
  ordinalNumber: number;
  data?: Utils.FormData<Model.IClaimJobItem> & { isWarrantyProduct: boolean };
  onDelete(data: Utils.FormData<Model.IClaimJobItem>, index: number): void;
  onUpdate(data: Partial<Model.IClaimJobItem>): void;
};

export const addRowButtonStyles: CSSProperties = {
  backgroundColor: 'rgb(240, 139, 7)',
  color: Theme.Color.textAlt,
  marginLeft: 'auto',
};

export default function ClaimEditJobItem({ data, index, ordinalNumber, lossCodes, onDelete, onUpdate }: Props) {
  const { lang } = getLocales();
  const { getJobItemTaxesByPayee, getJobItemCorrection, getJobItemLabourRate } = useClaimJobEdit();
  const { payeeList, setOffset, setSearch, limit, offset, count } = useClaimEditJobPayeeItem();

  const payee = new ClaimJobItemPayee(data?.claimJobItemPayee);
  const countryCode =
    payee?.payee?.countryCode ?? payee?.client?.countryCode ?? payee?.dealer?.countryCode ?? CountryCode.CAN;
  const payeeInfo = payee?.client ?? payee?.dealer ?? payee?.payee;

  return (
    <tr>
      <td>{ordinalNumber + 1}</td>
      <td>
        {payeeInfo && data?.id ? (
          payeeInfo.displayInfo?.title
        ) : (
          <SelectInput
            name="claimJobItemPayee"
            data={payeeList?.map((it) => ({ ...it.displayInfo, id: `${it.displayInfo.id}-${it.payeeType?.id}` }))}
            displayText={payee?.displayInfo?.title}
            disabled={!!data.usBankVirtualCardId}
            value={`${
              data?.claimJobItemPayee?.payee?.id ??
              data?.claimJobItemPayee?.client?.id ??
              data?.claimJobItemPayee?.dealer?.id
            }-${data?.claimJobItemPayee?.payeeType?.id}`}
            limit={limit}
            offset={offset}
            count={count}
            onSearch={setSearch}
            onOffsetChange={({ value: offset }) => setOffset(offset)}
            onChange={({ claimJobItemPayee: id }) => {
              const itemPayee = payeeList?.find(
                (it) => `${it.payee?.id ?? it.dealer?.id ?? it.client?.id}-${it.payeeType?.id}` === id
              );
              onUpdate({
                claimJobItemPayee: itemPayee,
                ...getJobItemTaxesByPayee(itemPayee),
                ...getJobItemCorrection(data?.jobItemTypeCode),
                ...getJobItemLabourRate(itemPayee),
              });
            }}
          />
        )}
      </td>
      <td>
        <SelectInput
          name="lossCode"
          data={lossCodes.map((it) => ({
            id: it.code,
            title: it.descnEn,
          }))}
          disabled={!!data.usBankVirtualCardId}
          value={data?.lossCode}
          onChange={onUpdate}
        />
      </td>
      {data?.jobItemTypeCode === LineItem.part && (
        <td>
          <TextInput
            name="partNumber"
            disabled={!!data.usBankVirtualCardId}
            value={data?.partNumber}
            onChange={onUpdate}
            maxLength={20}
          />
        </td>
      )}
      <td>
        <SelectInput
          name="statusCode"
          data={Object.values(PaymentStatus).map((it) => ({
            id: it,
            title: it,
          }))}
          disabled={!!data.usBankVirtualCardId}
          value={data?.statusCode}
          onChange={onUpdate}
        />
      </td>
      {data?.jobItemTypeCode === LineItem.labour && (
        <>
          <td>
            <NumberInput
              name="hoursWorked"
              value={data?.hoursWorked}
              onChange={onUpdate}
              disabled={!!data.usBankVirtualCardId}
              error={
                (data?.hoursWorked === null || data?.hoursWorked < 0) &&
                lang.mustBeMoreThanAmount.replace('{amount}', '0')
              }
            />
          </td>
          <td>
            <NumberInput
              name="labourRate"
              value={getJobItemLabourRate(payee)?.labourRate}
              onChange={onUpdate}
              readOnly
              style={{ input: { textAlign: 'right' } }}
              icon={<Icon>{data.currency}</Icon>}
            />
          </td>
        </>
      )}
      <td>
        <NumberInput
          name="correction"
          value={data?.correction}
          onChange={onUpdate}
          step={0.01}
          readOnly={
            !!data.usBankVirtualCardId ||
            (data.jobItemTypeCode === LineItem.deductible && data?.isWarrantyProduct) ||
            data.jobItemTypeCode === LineItem.labour
          }
          style={{ input: { textAlign: 'right' } }}
          icon={<Icon>{data.currency}</Icon>}
        />
      </td>
      <td>
        <NumberInput
          name="adjudication"
          value={data?.adjudication}
          onChange={onUpdate}
          step={0.01}
          disabled={!!data.usBankVirtualCardId}
          style={{ input: { textAlign: 'right' } }}
          icon={<Icon>{data.currency}</Icon>}
        />
      </td>
      <td>
        <NumberInput
          name="gstHst"
          value={Number(((data?.gstHst || 0.0) * data.adjudication).toFixed(2))}
          onChange={({ gstHst }) =>
            onUpdate({
              gstHst: gstHst / data.adjudication,
            })
          }
          step={0.01}
          readOnly={!!data.usBankVirtualCardId}
          error={
            data?.gstHst > 1 &&
            lang.mustBeEqualOrLessThanAmount.replace('{amount}', `Adjusted amt: ${data.adjudication.toFixed(2)}`)
          }
          style={{ input: { textAlign: 'right' } }}
          icon={<Icon>{data.currency}</Icon>}
        />
      </td>
      {countryCode === CountryCode.CAN && (
        <td>
          <NumberInput
            name="provincialSalesTax"
            value={Number(((data?.provincialSalesTax || 0.0) * data.adjudication).toFixed(2))}
            readOnly={!!data.usBankVirtualCardId}
            onChange={({ provincialSalesTax }) =>
              onUpdate({
                provincialSalesTax: provincialSalesTax / data.adjudication,
              })
            }
            error={
              data?.provincialSalesTax > 1 &&
              lang.mustBeEqualOrLessThanAmount.replace('{amount}', `Adjusted amt: ${data.adjudication.toFixed(2)}`)
            }
            step={0.01}
            style={{ input: { textAlign: 'right' } }}
            icon={<Icon>{data.currency}</Icon>}
          />
        </td>
      )}
      <td>
        <NumberInputTextValue
          name="total"
          value={data.adjudication + data.adjudication * (data?.gstHst + data?.provincialSalesTax)}
          readOnly
          onChange={onUpdate}
          style={{ input: { textAlign: 'right' } }}
          icon={<Icon>{data.currency}</Icon>}
        />
      </td>
      <td>
        <Button
          class={ThemeButtonCircle}
          media={<Icon class="fas-trash" />}
          onClick={(data) => onDelete(data, index)}
          data={data}
          htmlElementProps={{
            title: lang.delete,
          }}
          disabled={!!data.usBankVirtualCardId}
        />
      </td>
    </tr>
  );
}
