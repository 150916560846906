import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Icon from 'assets/components/icon/Icon';
import Page from 'assets/components/page/Page';
import ThemePageStatic2 from 'assets/components/page/themes/Theme.Page.Static.2';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { ascLogoWhite } from 'assets/themes/assets/Images';
import Theme from 'assets/themes/Theme.Common';
import { useHistory } from 'react-router';
import { publicClaimSubmitIndexRoute } from './Claim.Submit.Index';

export const publicClaimResponsePageIndexRoute = '/public/claim/:claimNumber';
const PublicClaimSubmitResponsePageIndex = () => {
  const _history = useHistory();
  const { lang } = getLocales();
  const {
    params: { claimNumber },
  } = usePageRouter<{ claimNumber: string }, undefined>({
    route: publicClaimResponsePageIndexRoute,
  });

  return (
    <Page
      class={ThemePageStatic2}
      header={
        <Card
          class={ThemeCardRow}
          media={<img style={{ objectFit: 'contain' }} src={ascLogoWhite} />}
          style={{
            card: { flexDirection: 'column' },
            cardMedia: { width: '400px', objectFit: 'contain' },
            cardInfoTitle: { color: Theme.Color.highlightLight },
          }}
        />
      }
      render={
        <Card
          class={ThemeCardRow}
          style={{
            card: { flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
            cardTitle: {
              color: Theme.Color.success,
              fontSize: Theme.FontSize.XL,
              textAlign: 'center',
              margin: '25px 0',
            },
            cardSubtitle: { fontWeight: 'bold', fontSize: Theme.FontSize.L, textAlign: 'center' },
          }}
          title={lang.yourClaimHasBeenSuccessfullySubmitted}
          subtitle={claimNumber}
          subHighlight={lang.yourClaimHasBeenSuccessfullySubmitted}
        />
      }
      footer={
        <Button
          onClick={() => _history.push(publicClaimSubmitIndexRoute)}
          class={ThemeButtonCircle}
          render={`${lang.addNew} ${lang.claim}`}
          media={<Icon class="fas-plus" />}
          active
          htmlElementProps={{ title: `${lang.addNew} ${lang.claim}` }}
        />
      }
    />
  );
};

export default PublicClaimSubmitResponsePageIndex;
