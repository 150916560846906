import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { defaultLimit, defaultOffset } from 'config/Api.Config';
import { compact } from 'lodash';
import dealerDeleteApi from 'models/dealerManagement/dealer/delete/Api.Dealer.Delete';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import { useMemo } from 'react';
import { dealerIndexRoute } from './Dealer.Index';

export enum DealerAction {
  EDIT = 'edit',
  DELETE = 'delete',
}
export type VerifyDealerAction = Utils.VerifyExtends<Module.Claims.Dealer.Actions, Utils.ValueOf<typeof DealerAction>>;
export default function useDealer() {
  const { permissions, user } = useAuthStore();
  const pageRouter = usePageRouter<Module.Claims.Dealer.Params, Module.Claims.Dealer.Query>({
    route: dealerIndexRoute,
  });
  const canView = permissions?.CREATE_DEALER || permissions?.EDIT_DEALER || permissions?.DELETE_DEALER;
  const listApi = useApi(
    {
      action: Dealer.list,
      default: { limit: defaultLimit, offset: defaultOffset },
      body: {
        ...pageRouter.query.list,
        distributorId: compact([user?.distributorId]),
      },
      callback: (req) => pageRouter.redirect({}, { ...pageRouter.query, list: req }),
      wait: !canView || !user,
    },
    [canView, user]
  );

  const selected = useMemo(() => {
    return listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString());
  }, [listApi.payload?.data, pageRouter.params.id]);

  const deleteApi = useApi({
    action: dealerDeleteApi,
    callback: () => listApi.execute((body) => body),
    wait: true,
  });

  return {
    canView,
    pageRouter,
    listApi,
    deleteApi,
    selected,
    permissions,
    views: {
      [DealerAction.DELETE]: pageRouter.params.action === DealerAction.DELETE && selected && permissions?.DELETE_DEALER,
      [DealerAction.EDIT]:
        pageRouter.params.action === DealerAction.EDIT &&
        ((permissions?.CREATE_DEALER && !selected) || (permissions?.EDIT_DEALER && selected)),
    },
  };
}

export function useDealerProvider() {
  return useDataProvider<ReturnType<typeof useDealer>>();
}
