import { addLog } from 'assets/components/feedback/Feedback';
import useForm from 'assets/components/form/hooks/Form';
import useApi from 'assets/hooks/api/useApi';
import { getLocales } from 'assets/locales/Locale';
import { date, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { createPathFromRoute } from 'assets/utils/dom/UrlParsing';
import { isEmail, isValidPhoneNumber } from 'assets/utils/parsersAndValidation/Validators';
import { HttpStatus } from 'config/Api.Config';
import { first, set } from 'lodash';
import claimSavePublic from 'models/claims/claim/savePublic/Api.Claim.SavePublic';
import claimDocumentSavePublicApi from 'models/claims/claimDocument/savePublic/Api.ClaimDocument.SavePublic';
import ClaimType from 'models/claims/claimType/Model.ClaimType';
import Payee from 'models/claims/payee/Model.Payee';
import PayeeType from 'models/claims/payeeType/Model.PayeeType';
import Dealer from 'models/dealerManagement/dealer/Model.Dealer';
import { ClaimCreatorOptions } from 'models/enums/ClaimCreation';
import ClaimStatusCode from 'models/enums/ClaimStatusCode';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { publicClaimResponsePageIndexRoute } from './Claim.Submit.ResponsePage';

export enum PublicClaimSubmitActions {
  CREATE_REPAIR_FACILITY = 'createRepairFacility',
}

export default function usePublicClaimSubmit() {
  const { lang } = getLocales();
  const _history = useHistory();
  const [action, setAction] = useState<PublicClaimSubmitActions>(undefined);

  const form = useForm<Module.Claims.Claim.Submit.Form>({
    default: {
      claim: {
        submitedDateUtc: date().tz('UTC').format(defaultServerDateTimeFormat),
        statusCode: ClaimStatusCode.OPEN_ACTIVE_SUBMITTED_OTHER,
        createdByPayee: {
          payeeTypeId: PayeeType.REPAIR_FACILITY_ID,
        },
        claimJobs: [],
      },
      claimDocuments: [],
    },
    onSubmit: async (data) => {
      const claimResponse = await claimSavePublic({
        body: {
          ...data.claim,
          claimJobs: [
            {
              jobNumber: 1,
              claimTypeId: data?.claimTypeId,
              cause: data?.cause,
              customerComplaint: data?.customerComplaint,
              correction: data?.correction,
            },
          ],
        },
        params: {
          clientName: data.claim.contract?.client?.lastName,
          vin: data.claim.contract?.vehicle?.vin,
          contractNumber: data.claim.contract?.appNumber,
        },
      });
      const newClaim = claimResponse.payload;
      if (newClaim?.id && claimResponse.status === HttpStatus.ok) {
        for (const document of data.claimDocuments) {
          document.claimId = newClaim?.id;
          await claimDocumentSavePublicApi({
            body: document,
            params: {
              clientName: data.claim.contract?.client?.lastName,
              vin: data.claim.contract?.vehicle?.vin,
              contractNumber: data.claim.contract?.appNumber,
              id: newClaim?.id?.toString(),
            },
          });
        }
        addLog({ success: claimResponse.message || lang.saveSuccess });
        _history.push(createPathFromRoute(publicClaimResponsePageIndexRoute, { claimNumber: newClaim?.number }));
      } else {
        addLog({ error: claimResponse.message || lang.saveError });
      }
      // form.reload();
    },
    validation: (data, errors) => {
      if (!data.claimDocuments) set(errors, 'claimDocuments._objectError', lang.required);
      if (!data.claim?.contract) set(errors, 'claim.contract._objectError', lang.required);
      // Payee validation
      if (!data.claim?.createdByPayee?.id && data?.claimCreationOption === ClaimCreatorOptions.REPAIR_FACILITY)
        set(errors, 'claim.createdByPayee.id', lang.required);
      if (data?.claimCreationOption === ClaimCreatorOptions.REPAIR_FACILITY) {
        if (!data.claim?.createdByPayee?.name) set(errors, 'claim.createdByPayee.name', lang.required);
        if (!data.claim?.createdByPayee?.email) set(errors, 'claim.createdByPayee.email', lang.required);
        if (!isEmail(data.claim?.createdByPayee?.email))
          set(
            errors,
            'claim.createdByPayee.email',
            lang.invalidFormatRequires.replace('{format}', 'example@example.com')
          );
        // TODO: See if this is required???
        // if (!data.claim?.createdByPayee?.phoneNumber) set(errors, 'claim.createdByPayee.phoneNumber', lang.required);
        if (data.claim?.createdByPayee?.phoneNumber && !isValidPhoneNumber(data.claim?.createdByPayee?.phoneNumber))
          set(
            errors,
            'claim.createdByPayee.phoneNumber',
            lang.invalidFormatRequires.replace('{format}', '###-###-####')
          );
      }
      //
      if (data.claimCreationOption === ClaimCreatorOptions.DEALERSHIP && !data.claim?.dealerId)
        set(errors, 'claim.dealerId', lang.required);
      if (!data?.claimCreationOption) set(errors, 'claimCreationOption', lang.required);
      if (!data.claim?.publicContactEmail) set(errors, 'claim.publicContactEmail', lang.required);
      if (!isEmail(data.claim?.publicContactEmail))
        set(errors, 'claim.publicContactEmail', lang.invalidFormatRequires.replace('{format}', 'example@example.com'));
      if (!data.claim?.publicContactName) set(errors, 'claim.publicContactName', lang.required);
      if (!data.claim?.publicContactPhone) set(errors, 'claim.publicContactPhone', lang.required);
      if (data.claim?.publicContactPhone && !isValidPhoneNumber(data.claim?.publicContactPhone))
        set(errors, 'claim.publicContactPhone', lang.invalidFormatRequires.replace('{format}', '###-###-####'));
    },
    beforeUpdate: (data) => {
      if (data.claimCreationOption !== ClaimCreatorOptions.DEALERSHIP) delete data?.claim?.dealerId;
      if (data.claimCreationOption !== ClaimCreatorOptions.REPAIR_FACILITY) delete data?.claim?.createdByPayee;

      switch (data.claimCreationOption) {
        case ClaimCreatorOptions.REPAIR_FACILITY:
          data.claim.statusCode = ClaimStatusCode.OPEN_ACTIVE_SUBMITTED_REPAIRFACILITY;
          break;
        case ClaimCreatorOptions.DEALERSHIP:
          data.claim.statusCode = ClaimStatusCode.OPEN_ACTIVE_SUBMITTED_DEALERSHIP;
          break;
        case ClaimCreatorOptions.CUSTOMER:
          data.claim.statusCode = ClaimStatusCode.OPEN_ACTIVE_SUBMITTED_CUSTOMER;
          break;
        default:
          data.claim.statusCode = ClaimStatusCode.OPEN_ACTIVE_SUBMITTED_OTHER;
          break;
      }

      return data;
    },
  });

  //#region APIS
  const payeeListApi = useApi({
    action: Payee.list,
    wait: form.data.claimCreationOption === ClaimCreatorOptions.REPAIR_FACILITY,
    body: { payeeTypeId: [PayeeType.REPAIR_FACILITY_ID] },
  });
  const dealerListPublicApi = useApi({
    action: Dealer.listPublic,
    wait: form.data.claimCreationOption === ClaimCreatorOptions.DEALERSHIP,
  });
  const claimTypesListPublicApi = useApi({
    action: ClaimType.listPublic,
    body: { productId: [form.data?.claim?.contract?.productId] },
    wait: !form.data?.claim?.contract?.productId,
  });
  //#endregion

  useEffect(() => {
    if (form.data.claim?.contract?.dealer?.representativeId)
      dealerListPublicApi.execute({ distributorId: [form.data.claim?.contract?.dealer?.representativeId] });
  }, [form.data.claim?.contract?.dealer?.representativeId]);

  //#region Claim Types
  useEffect(() => {
    if (form.data.claim?.contract?.productId)
      claimTypesListPublicApi.execute({ productId: [form.data.claim?.contract?.productId] });
  }, [form.data.claim?.contract?.productId]);

  const onlyClaimType = claimTypesListPublicApi?.payload?.data?.length === 1;
  useEffect(() => {
    if (onlyClaimType) {
      form.update({ claimTypeId: first(claimTypesListPublicApi?.payload?.data)?.id });
    }
  }, [claimTypesListPublicApi?.payload?.data]);
  const claimTypeValue = onlyClaimType ? first(claimTypesListPublicApi?.payload?.data)?.id : form.data?.claimTypeId;
  //#endregion

  return {
    form,
    dealerListPublicApi,
    claimTypesListPublicApi,
    payeeListApi,
    action,
    setAction,
    claimTypeValue,
    onlyClaimType,
  };
}
