import { addLog } from 'assets/components/feedback/Feedback';
import useApi from 'assets/hooks/api/useApi';
import usePageRouter from 'assets/hooks/pageRouter/usePageRouter';
import { getLocales } from 'assets/locales/Locale';
import { useAuthStore } from 'assets/providers/authStore/Provider.AuthStore';
import { useDataProvider } from 'assets/providers/data/DataProvider';
import { combineStrings } from 'assets/utils/data/String';
import { defaultLimit, defaultOffset, HttpStatus } from 'config/Api.Config';
import { compact, isEmpty, keys } from 'lodash';
import Product from 'models/productManagement/product/Model.Product';
import Subfee from 'models/productManagement/subfee/Model.Subfee';
import Contract from 'models/sales/contract/Model.Contract';
import contractRevisionsApi from 'models/sales/contract/revisions/Api.Contract.Revisions';
import contractStatusFlowApi from 'models/sales/contract/statusFlow/Api.Contract.StatusFlow';
import contractConsentResendApi from 'models/sales/contractConsent/resend/Api.ContractConsent.Resend';
import ContractRevision from 'models/sales/contractRevision/Model.ContractRevision';
import Lender from 'models/sales/lender/Model.Lender';
import { useEffect, useMemo, useState } from 'react';
import { salesContractIndexRoute } from './Sales.Contract.Index';
import contractExportApi from 'models/sales/contract/export/Api.Contract.Export';
import { date } from 'assets/utils/data/Date';

export enum SalesContractAction {
  EDIT = 'edit',
  INFO = 'info',
  EXPORT = 'export',
}
export enum SalesContractInfoTab {
  INFO = 'info',
  DOCUMENTS = 'documents',
  REVISIONS = 'revisions',
}
export function useSalesContractRouter() {
  return usePageRouter<Module.Sales.Contract.Params, Module.Sales.Contract.Query>({
    route: salesContractIndexRoute,
  });
}
export default function useSalesContract() {
  const { permissions, user } = useAuthStore();
  const pageRouter = useSalesContractRouter();
  const { lang } = getLocales();

  const contractStatusInfoApi = useApi({ action: contractStatusFlowApi });
  const contractStatusInfo = contractStatusInfoApi.payload;

  const canView = permissions.CREATE_CONTRACT || permissions.EDIT_CONTRACT || permissions.DELETE_CONTRACT;
  const listApi = useApi({
    action: Contract.list,
    default: {
      limit: defaultLimit,
      offset: defaultOffset,
      dealerId: user?.dealers?.map((d) => d.id) ?? [],
      distributorId: user.distributorId ?? undefined,
    },
    body: pageRouter.query.list,
    callback: (req) => pageRouter.redirect({ ...pageRouter.params }, { ...pageRouter.query, list: req }),
    wait: !canView,
  });
  const findApi = useApi(
    {
      action: Contract.find,
      wait: !canView || !pageRouter.params.id,
      body: {
        id: Number(pageRouter.params.id),
      },
    },
    [pageRouter.params.id]
  );
  const exportApi = useApi({
    action: Contract.export,
    wait: !canView || isEmpty(listApi?.payload?.data),
  });

  const selected = useMemo(() => {
    return (
      listApi.payload?.data?.find((it) => it.id.toString() === pageRouter.params.id?.toString()) ?? findApi?.payload
    );
  }, [listApi.payload?.data, pageRouter.params.id, pageRouter.params.action]);

  const productApi = useApi({
    action: Product.find,
    wait: true,
  });
  useEffect(() => {
    if (selected?.productId) productApi.execute({ id: selected.productId });
    else productApi.reset(true);
  }, [selected?.productId]);

  const lenderApi = useApi({
    action: Lender.find,
    wait: true,
  });
  useEffect(() => {
    if (selected?.vehicle?.lenderId) lenderApi.execute({ id: selected.vehicle?.lenderId });
    else lenderApi.reset(true);
  }, [selected?.vehicle?.lenderId]);
  useEffect(() => {
    if (lenderApi?.payload && lenderApi?.payload?.id === selected?.vehicle?.lenderId)
      selected.vehicle.lender = new Lender(lenderApi?.payload);
  }, [lenderApi?.payload]);

  const subfeeApi = useApi({
    action: Subfee.list,
    wait: true,
  });
  useEffect(() => {
    const ids = compact(keys(selected?.subfees)).map((it) => Number(it));
    if (selected?.id && ids.length) {
      subfeeApi.execute({
        id: ids,
      });
    } else {
      subfeeApi.reset(true);
    }
  }, [selected?.id]);

  const statuses = useMemo(() => {
    return keys(contractStatusInfo).map((status) => {
      const [primary, secondary, reason] = status.split('_');
      const mainStatus = combineStrings(' - ', primary, secondary);
      return {
        id: status,
        subtitle: mainStatus,
        title: reason ?? mainStatus,
      };
    });
  }, [contractStatusInfo]);

  //#region Revisions
  const revisionsApi = useApi({ action: contractRevisionsApi, wait: true });
  useEffect(() => {
    if (selected?.id) {
      revisionsApi.execute({ id: selected?.id });
    } else {
      revisionsApi.reset(true);
    }
  }, [selected?.id]);

  const [selectedRevision, selectRevision] = useState<ContractRevision>();
  const revisions = useMemo(() => {
    return revisionsApi.payload?.map((data) => {
      return new ContractRevision({
        ...data,
        contractJson: { ...data.contractJson, product: selected?.product?.toJSON() },
      });
    });
  }, [revisionsApi.payload]);
  //#endregion

  async function resendContractConsent({
    id,
    requestType,
  }: {
    id: number;
    requestType: Model.Enum.ContractConsentRequestType;
  }) {
    const res = await contractConsentResendApi({ id, requestType });
    if (res.status === HttpStatus.ok) addLog({ success: lang.emailSuccessfullySent });
    else addLog({ error: lang.saveError });
  }

  async function exportContractsToExcel() {
    if (!isEmpty(listApi?.payload?.data)) {
      const res = await contractExportApi({ contractIds: listApi?.payload?.data?.map((it) => it.id) });
      if (res.status === HttpStatus.ok) {
        const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res?.payload}`;
        const a = document.createElement('a');
        a.href = url;
        a.download = `Contracts_${date().format("DD-MM-YY")}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        addLog({ success: lang.exportSuccess });
      }
      else addLog({ error: lang.exportError })
    } else addLog({ warning: lang.noContractDefined });
  }

  return {
    canView,
    pageRouter,
    listApi,
    exportApi,
    selected,
    selectedProduct: productApi?.payload,
    permissions,
    revisionsApi,
    subfeeApi,
    findApi,
    lender: lenderApi?.payload,
    resendContractConsent,
    exportContractsToExcel,
    statuses,
    revisions,
    selectedRevision,
    selectRevision,
  };
}

export function useSalesContractProvider() {
  return useDataProvider<ReturnType<typeof useSalesContract>>();
}
