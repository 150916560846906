const en = {
  selectAll: 'Select all',
  sendPaymentRequest: 'Send payment request',
  sendPaymentRequestTo: 'Send payment request to',
  payeeEmailIsRequired: 'Payee email is required',
  paymentRequiresClaimToBeInCorrectStatus: 'Payment requires claim to be in correct status',
  paymentIsAvailableOnlyFor: 'Payment is available only for (CAD): US bank',
  paymentRequestEmailIsAvailableOnlyFor: 'Payment request email is available only for EFT / Cheque',
  sendPaymentRequestEmail: 'Send payment request email',
  enterAccountingEmail: 'Enter accounting email',
  pleaseSaveJobToDoPayment: 'Please save job to do payment',
  youAlreadyHavePendingPayment: 'You already have pending payment',
  alreadyPaid: 'Already paid',
  en: 'English',
  fr: 'French',
  month: 'Month',
  months: 'Months',
  goToTop: 'Go to top',
  cancel: 'Cancel',
  submit: 'Submit',
  supportingDocumentation: 'Supporting documentation',
  generated: 'Generated',
  documentationAggregate: 'Documentation aggregate',
  contractSubmitMessage: "Are you sure you're ready to submit this contract?",
  empty: 'Empty',
  filters: 'Filters',
  sort: 'Sort',
  sortBy: 'Sort by',
  filterBy: 'Filter by',
  apply: 'Apply',
  revise: 'Revise',
  youWillKeepOwnership: 'You will keep ownership',
  ownershipWillBeRemoved: 'Ownership will be removed',
  takeOwnershipOfTheClaim: 'Take ownership of the claim',
  transferOwnershipTo: 'Transfer ownership to',
  reset: 'Reset',
  on: 'On',
  used: 'Used',
  off: 'Off',
  search: 'Search',
  clear: 'Clear',
  return: 'Return',
  pricingDistributions: 'Pricing distributions',
  distributorDistributions: 'Distributor distributions',
  waiting: 'Waiting',
  uploaded: 'Uploaded',
  uploadedBy: 'Uploaded by',
  at: 'at',
  missing: 'Missing',
  additionalDocumentation: 'Additional documentation',
  generalInformation: 'General information',
  unitNumber: 'Unit number',
  appNumber: 'App number',
  taxExempt: 'Tax exempt',
  taxExemptionBand: 'Tax exemption band',
  odometerReading: 'Odometer reading',
  stockNumber: 'Stock number',
  purchasePrice: 'Purchase price',
  purchaseState: 'Purchase state',
  purchaseType: 'Purchase type',
  cash: 'Cash',
  finance: 'Finance',
  lease: 'Lease',
  lineofcredit: 'Line of Credit',
  financedAmount: 'Financed amount',
  interestRate: 'Interest rate',
  term: 'Term',
  customInfo: 'Pricing info',
  customInfoGroup: 'Pricing info group',
  maxKm: 'Max KM',
  totalItems: 'Total items',
  show: 'Show',
  showMore: 'Show more',
  close: 'Close',
  itemsShown: 'Items shown',
  itemsSelected: 'Items selected',
  confirmByCheckbox: 'Please click the checkbox to confirm your actions',
  confirmByFourDigits: 'Please repeat number bellow to confrim your actions',
  userConfirmationRequired: 'User confirmation required',
  userMenu: 'User menu',
  closeMenu: 'Close menu',
  openMenu: 'Open menu',
  writeHere: 'Write here',
  confirm: 'Confirm',
  username: 'Username',
  password: 'Password',
  login: 'Login',
  distributionDoesntMatchOriginalPrice: "Distribution doesn't match original price",
  pricesMustMatchCriteria: 'Prices must match criteria',
  invalidFormatRequires: 'Invalid format, requires: {format}',
  mustNotBeEmpty: 'Must not be empty',
  minMaxMissmatchMessage: 'Max value must be greater than min value',
  cantChangeProduct: "You can't change product",
  mustBeEqualOrMoreThanAmount: 'Must be equal or more than {amount}',
  mustBeEqualOrLessThanAmount: 'Must be equal or less than {amount}',
  mustBeLessThanAmount: 'Must be less than {amount}',
  mustBeMoreThanAmount: 'Must be more than {amount}',
  readyForUpdate: 'Ready for update',
  cannotBeNegativeNumber: 'Cannot be negative number',
  passwordValidityMessage: 'Password must be at least 7 charactes long including at least one number and letter',
  mustSelectAtleastOneOption: 'Must Select atleast one option',
  passwordsDoNotMatch: 'Passwords do not match',
  sessionHasTimedOut: 'Session has timed out',
  invalidForm: 'Invalid form',
  allValuesMustBeUnique: 'All values must be unique',
  newPassword: 'New password',
  download: 'Download',
  confirmPassword: 'Confirm password',
  oldPassword: 'Old password',
  changePassword: 'Change password',
  resetPassword: 'Reset password',
  viewFiles: 'View files',
  passwordWasSuccessfullyChanged: 'Password was successfully changed',
  weWereUnableToChangeYourPassword: 'We were unable to change your password',
  passwordWasSuccessfullySet: 'Password was successfully set',
  weWereUnableToSetYourPassword: 'We were unable to set your password',
  invalidUsernameOrPassword: 'Invalid username or password',
  unknownDocument: 'Unknown document',
  statements: 'Statements',
  statement: 'Statement',
  statementSummary: 'Statement Summary',
  exclude: 'Exclude',
  DATE: 'Date',
  DATE_TIME: 'Date & time',
  TEXT: 'Text',
  LONG_TEXT: 'Long text',
  BOOLEAN: 'Yes/no answer',
  NUMBER: 'Whole number',
  DECIMAL: 'Decimal number',
  noInfo: 'No info',
  classes: 'Classes',
  noFiles: 'No files',
  number: 'Number',
  ok: 'Ok',
  badRequest: 'Bad request',
  unauthorized: 'Unauthorized',
  notFound: 'Not found',
  internalServerError: 'Internal server error',
  unknownError: 'Unknown error',
  vehicleModel: 'Vehicle model',
  vehicleMake: 'Vehicle make',
  vehicleMakeImportExcel: 'Import vehicle make',
  vehicleClass: 'Vehicle class',
  vehicleModels: 'Vehicle models',
  vehicleClassCopy: 'Copy vehicle class',
  vehicleClassCopyFrom: 'Copy from vehicle class',
  vehicleClassCopyTo: 'Copy to vehicle class',
  copy: 'Copy',
  models: 'Models',
  vehicleMakes: 'Vehicle makes',
  vehicleClasses: 'Vehicle classes',
  user: 'User',
  lenders: 'Lenders',
  lenderImportExcel: 'Import lender',
  users: 'Users',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  language: 'Language',
  profileImage: 'Profile image',
  contractDocuments: 'Contract documents',
  editMySettings: 'Edit my settings',
  ascendingByDate: 'Ascending by date',
  descendingByDate: 'Descending by date',
  ascendingByUserName: 'Ascending by user name',
  descendingByUserName: 'Descending by user name',
  ascendingByTerm: 'Ascending by term',
  descendingByTerm: 'Descending by term',
  ascendingByCode: 'Ascending by code',
  descendingByCode: 'Descending by code',
  application: 'Application',
  applicationMenu: 'Application menu',
  applications: 'Applications',
  dealerMenu: 'Dealer menu',
  startDate: 'Start date',
  endDate: 'End date',
  addComent: 'Add comment',
  name: 'Name',
  documentUpload: 'Document upload',
  edited: 'Edited',
  likes: 'Likes',
  like: 'Like',
  dislike: 'Dislike',
  makeCode: 'Make code',
  alternativeMakeCode: 'Alternative make code',
  class: 'Class',
  model: 'Model',
  year: 'Year',
  vehicleYear: 'Vehicle year',
  bodyType: 'Body type',
  deductible: 'Deductible',
  additionalRow: 'Additional row',
  trimLevel: 'Trim level',
  engineType: 'Engine type',
  driveLine: 'Drive line',
  bodyColor: 'Body color',
  url: 'Url',
  permission: 'Permission',
  newerFirst: 'Newer first',
  olderFirst: 'Older first',
  permissions: 'Permissions',
  changeMadeBy: 'Change made by',
  category: 'Category',
  categories: 'Categories',
  description: 'Description',
  secondaryDescription: 'Secondary description',
  source: 'Source',
  role: 'Role',
  roles: 'Roles',
  info: 'Info',
  discounts: 'Discounts',
  isPackage: 'This product is part of a Package',
  documentsGenerated: 'Documents generated',
  documentsSigned: 'Documents signed',
  documentsSentForSigning: 'Documents sent for siging',
  contractStatusWaiting: 'Waiting',
  contractStatusSaving: 'Saving',
  contractStatusGeneratingDocs: 'Generating documents',
  contractStatusUploadingFiles: 'Uploading files',
  contractStatusDone: 'Done',
  contractStatusFailed: 'Failed',
  changeContractStatus: 'Change contract status',
  productDocuments: 'Product documents',
  autoFilledDocuments: 'Auto filled documents',
  uiSettings: 'UI settings',
  additionalPermissions: 'Additional permissions',
  unlimited: 'Unlimited',
  delete: 'Delete',
  addNew: 'Add new',
  import: 'Import',
  viewAllContracts: 'View all contracts',
  signAll: 'Sign all',
  payAll: 'Pay all',
  mySettings: 'My settings',
  logout: 'Logout',
  accessedAt: 'Accessed at',
  accessLogs: 'Access logs',
  changeLogs: 'Change logs',
  notUnique: 'Not unique',
  welcome: 'Welcome',
  personalInfo: 'Personal info',
  accountInfo: 'Account info',
  currentLanguage: 'Current language',
  claim: 'Claim',
  claimNumber: 'Claim number',
  claimAuthorizationNumber: 'Claim authoraztion number',
  claimTotalAmount: 'Claim total amount',
  claimTotalTaxAmount: 'Claim total tax amount',
  claimTotalAmountWithTax: 'Claim total amount with tax',
  claimJob: 'Claim job',
  claimJobNotes: 'Claim job notes',
  claimExternalComments: 'Claim external comments',
  totalTax: 'Total tax',
  chooseExisting: 'Choose existing',
  yourClaimHasBeenSuccessfullySubmitted: 'Your claim has been successfully submitted!',
  claimType: 'Claim type',
  reportSettings: 'Report settings',
  aggregate: 'Aggregate',
  documents: 'Documents',
  contractDocument: 'Contract document',
  revisions: 'Revisions',
  uploadingPleaseWait: 'Uploading please wait',
  changeLayout: 'Change layout',
  dealers: 'Dealers',
  dealerGroup: 'Dealer Group',
  updateDealer: 'Update dealer',
  updateDistributorPrice: 'Update distributor price',
  clearDistributorPrice: 'Clear distributor price',
  openDistributorDistributionTable: 'Open distributor distribution table',
  openDealerPricingLimitationTable: 'Open dealer pricing limitation table',
  separate: 'Separate',
  moveDown: 'Move down',
  moveUp: 'Move up',
  groups: 'Groups',
  distributors: 'Distributors',
  distributorPricing: 'Distributor pricing',
  upload: 'Upload',
  default: 'Default',
  distributor: 'Distributor',
  isInactive: 'Is inactive',
  isPaymentRequired: 'Is payment required',
  primaryContactEmailAddress: 'Primary contact email address',
  streetAddress: 'Street address',
  city: 'City',
  searchLocation: 'Search location',
  primaryEmailAddress: 'Primary email address',
  primaryPhoneNumber: 'Primary phone number',
  representative: 'Representative',
  contractNumber: 'Contract number',
  vin: 'VIN',
  vinMustBeAtLeast8CharsLong: 'VIN must be at least 8 characters long.',
  clientName: 'Client name',
  status: 'Status',
  expiryDate: 'Expiry date',
  salePrice: 'Sale price',
  discountPrice: 'Discount price',
  distributorPrice: 'Distributor price',
  surchargesPrice: 'Surcharges price',
  originalPrice: 'Cost',
  actions: 'Actions',
  select: 'Select',
  unselect: 'Unselect',
  lossCode: 'Loss code',
  lossCodeCategory: 'Loss code category',
  code: 'Code',
  tax: 'Tax',
  primaryCode: 'Primary code',
  secondaryCode: 'Secondary code',
  descnEn: 'DescnEn',
  descnEn1: 'DescnEn1',
  componentId: 'Component ID',
  product: 'Product',
  productCategory: 'Product category',
  tier: 'Tier',
  tiers: 'Tiers',
  make: 'Make',
  products: 'Products',
  mainDistributorUser: 'Main distributor user',
  mainDistributorRepresentative: 'Main distributor representative',
  prices: 'Prices',
  goToPrices: 'Go to prices',
  example: 'Example',
  noNotes: 'No notes',
  logo: 'Logo',
  noComments: 'No comments',
  pleaseSaveJobToAddNotes: 'Please save job to add notes',
  allowMultiple: 'Allow multiple',
  required: 'Required',
  allowNotes: 'Allow notes',
  approvalRequired: 'Approval required',
  totalAggregate: 'Total aggregate',
  totalAggregatePriority: 'Total aggregate priority',
  maxClaimAmount: 'Max claim amount',
  maxClaimAmountPriority: 'Max claim amount priority',
  maxNumberOfClaims: 'Max number of claims',
  maxNumberOfClaimsPriority: 'Max number of claims priority',
  amount: 'Amount',
  adjustment: 'Adjustment',
  isActive: 'Is active',
  priority: 'Priority',
  insurer: 'Insurer',
  insurers: 'Insurers',
  version: 'Version',
  newRepairVisit: 'New repair visit',
  serviceDetails: 'Service details',
  contract: 'Contract',
  contracts: 'Contracts',
  quote: 'Quote',
  quotes: 'Quotes',
  vehicle: 'Vehicle',
  vehicles: 'Vehicles',
  vehicleInfo: 'Vehicle info',
  customer: 'Customer',
  save: 'Save',
  requiresSyncing: 'Requires syncing',
  jobs: 'Jobs',
  saveDraft: 'Save draft',
  updateDraft: 'Update draft',
  deleteDraft: 'Delete draft',
  drafts: 'Drafts',
  prev: 'Prev',
  next: 'Next',
  causeOfFailure: 'Cause of failure',
  customerComplaint: 'Customer complaint',
  customerConcern: 'Customer concern',
  correctionDetails: 'Correction details',
  newJob: 'New job',
  job: 'Job',
  noJobs: 'No jobs',
  contractWasSuccessFullyCreated: 'Contract was successfully created',
  client: 'Client',
  clients: 'Clients',
  vertaforeCustomerNumber: 'Vertafore customer number',
  hasVertaforeCustomerNumber: 'Has Vertafore customer number',
  syncWithVertafore: 'Sync with Vertafore',
  review: 'Review',
  finish: 'Finish',
  contactClientAndVehicleInfo: 'Contract, client & vehicle info',
  partNumber: 'Part #',
  reason: 'Reason',
  payee: 'Payee',
  notes: 'Notes',
  correction: 'Correction',
  adjudication: 'Adjudication',
  typeCode: 'Type code',
  paymetMethod: 'Paymet method',
  createdBy: 'Created by',
  total: 'Total',
  claims: 'Claims',
  claimTypes: 'Claim types',
  lossCodes: 'Loss codes',
  lossCodesImportExcel: 'Import loss codes',
  settings: 'Settings',
  currency: 'Currency',
  parts: 'Parts',
  labour: 'Labour',
  labourRate: 'Labour rate',
  other: 'Other',
  ttl: 'Ttl (bef. tax)',
  deduct: 'Deduct',
  pending: 'Pending',
  paidAmount: 'Paid amt',
  clickOrDropFilesHere: 'Click or drop files here',
  files: 'Files',
  allowed: 'Allowed',
  fileUploadSize: 'File upload size',
  uploadError: 'Upload error',
  done: 'Done',
  justAMoment: 'Just a moment',
  approved: 'Approved',
  pleaseSaveClaimJobsToUploadDocuments: 'Pleaase save claim jobs to upload documents',
  createSimpleClaim: 'Create simple claim',
  add: 'Add',
  addPlaceholder: 'Add placeholder',
  addEmailTemplatePlaceholder:
    'Select the input field where you want the placeholder, then choose your desired placeholder text.',
  type: 'Type',
  property: 'Property',
  address: 'Address',
  payeeTypes: 'Payee types',
  payeeType: 'Payee type',
  dealer: 'Dealer',
  streetNumber: 'Street number',
  streetName: 'Street name',
  postalCode: 'Postal code',
  zipCode: 'ZIP code',
  state: 'State',
  muncipality: 'Muncipality',
  provinceCode: 'Province code',
  province: 'Province',
  countryCode: 'Country code',
  uploadedWithSimpleClaim: 'Uploaded with simple claim',
  new: 'New',
  expiryFromDate: 'Expiry from date',
  expiryToDate: 'Expiry to date',
  effectiveFromDate: 'Effective from date',
  effectiveToDate: 'Effective to date',
  createdAtFromDate: 'Created at from date',
  createdAtToDate: 'Created at to date',
  createdAt: 'Created at',
  fromDate: 'From date',
  toDate: 'To date',
  lastModified: 'Last modified',
  firstModified: 'First modified',
  decidedDate: 'Decided date',
  paymentDate: 'Payment date',
  ascendingByName: 'Ascending by name',
  descendingByName: 'Descending by name',
  pleaseEnterRequiredInfoBellow: 'Please enter required info bellow',
  noStatusDefined: 'No status defined',
  newlyCreated: 'Newly created',
  statusError: 'Status error',
  edit: 'Edit',
  viewMore: 'View more',
  viewLess: 'View less',
  noPayee: 'No payee',
  print: 'Print',
  pay: 'Pay',
  preview: 'Preview',
  price: 'Price',
  quantity: 'Quantity',
  unknownProduct: 'Unknown product',
  unknown: 'Unknown',
  open: 'Open',
  changeStatus: 'Change status',
  changeOwner: 'Change owner',
  welcomeToAutoshieldCanada: 'Welcome to Auto Shield Canada',
  descriptionOfDamage: 'Description of damage',
  submitANewClaim: 'Submit a New Claim',
  searchContractEnteringVinAndContractNumberOrClientLastName:
    "Search for a contract by entering a VIN and either the Contract Number or Client's Last Name",
  details: 'Details',
  owner: 'Owner',
  copyDefaultPricesHere: 'Copy default prices here',
  noItemsAdded: 'No items added',
  itemsAdded: 'items added',
  minPrice: 'Min price',
  maxPrice: 'Max price',
  showTables: 'Show tables',
  notAssigned: 'Not assigned',
  backToClaim: 'Back to claim',
  indemnityCalculation: 'Indemnity calculation',
  createClaimForContract: 'Create claim for contract',
  comments: 'Comments',
  hideTables: 'Hide tables',
  youNeedToSelectOneDocument: 'You need to select one document',
  repairFacility: 'Repair facility',
  phoneNumber: 'Phone number',
  iAmCreatingClaimAsRepairFacility: 'I am creating claim as repair facility',
  contactName: 'Contact name',
  contact: 'Contact',
  emailTemplates: 'Email templates',
  emailTemplate: 'Email template',
  claimStatus: 'Claim status',
  senderEmail: 'Sender email',
  senderName: 'Sender name',
  noOwnership: 'No ownership',
  calculation: 'Calculation',
  unknownUser: 'Unknown user',
  subject: 'Subject',
  htmlBody: 'Html body',
  recipientsWithRole: 'Recipients with role',
  payeeRequired: 'Payee is required',
  payees: 'Payees',
  sourceAndLogs: 'Source & logs',
  logs: 'Logs',
  saveSuccess: 'Form was successfully saved',
  saveError: 'An error occurred while saving form',
  importSuccess: 'Import successfully finished!',
  importError: 'An error occurred while importing file!',
  exportSuccess: 'Export succesfully finished!',
  exportError: 'An error occurred while exporting file!',
  export: 'Export',
  dontSendEmail: "Don't send email",
  additionalRecipentEmails: 'Additional recipient emails',
  noChangesWereMade: 'No changes were made',
  claimOwner: 'Claim owner',
  sendTo: 'Send to',
  addNote: 'Add note',
  minOdometer: 'Min odometer reading',
  maxOdometer: 'Max odometer reading',
  limit: 'Limit',
  limits: 'Limits',
  program: 'Program',
  programName: 'Program name',
  programs: 'Programs',
  ban: 'Ban',
  pricingDistributionTemplates: 'Pricing distribution templates',
  pricingDistributionTemplate: 'Pricing distribution template',
  openPricingDistributionTable: 'Open pricing distribution table',
  openSubfeeDistributionTable: 'Open subfee distribution table',
  openDealerDistributionDiscountTable: 'Open dealer distribution discount table',
  openDealerDistributorDistributionDiscountTable: 'Open dealer distributor distribution discount table',
  isForNewVehicle: 'Is for new vehicle',
  isNew: 'Is new',
  suggestedRetailPrice: 'Suggested retail price',
  minAmount: 'Min amount',
  maxAmount: 'Max amount',
  additionType: 'Addition type',

  effectiveDate: 'Effective date',
  dateOfLoss: 'Date of loss',
  typeOfLoss: 'Type of loss',
  dealership: 'Dealership',
  lender: 'Lender',
  comment: 'Comment',
  mailingAddress: 'Mailing address',
  preOwnedVehicle: 'Pre-Owned Vehicle',
  preOwned: 'Pre-Owned',
  claimIndemnityCalculation: 'Claim indemnity calculation',

  report: 'Report',
  totalAmountWithTaxes: 'Total amount with taxes',
  taxes: 'Taxes',
  documentTemplates: 'Document templates',
  totalAmount: 'Total amount',
  addition: 'Addition',
  subtraction: 'Subtraction',
  multiplication: 'Multiplication',
  division: 'Division',
  percent: 'Percent',
  notAllRequiredDocumentInfoWasProvided: 'Not all required document info was provided',
  label: 'Label',
  isRequired: 'Is required',
  isDisabled: 'Is disabled',
  group: 'Group',
  key: 'Key',
  coApplicantFirstName: 'Co-applicant first name',
  coApplicantLastName: 'Co-applicant last name',
  isHighlighted: 'Is highlighted',
  summationOperation: 'Summation operation',
  totalAmountOperation: 'Total amount operation',
  secondaryValue: 'Secondary value',
  primaryValue: 'Primary value',
  totalValue: 'Total value',
  askForInput: 'Ask for input',
  copyValue: 'Copy value',
  goToProductEdit: 'Go to product edit',
  sumValues: 'SUM values',
  primaryUsage: 'Primary usage',
  table: 'Table',
  item: 'Item',
  row: 'Row',
  alreadyExists: 'Already exists',
  noContractDefined: 'No contract defined',
  selectLanguage: 'Select language',
  pricingLimitTemplate: 'Pricing limit tempalte',
  pricingLimitTemplates: 'Pricing limit tempaltes',
  pricingPlan: 'Pricing plan',
  pricingPlans: 'Pricing plans',
  surcharge: 'Surcharge',
  surchargeDocuments: 'Surcharge documents',
  notification: 'Notification',
  surcharges: 'Surcharges',
  paymentOptions: 'Payment options',
  paymentPrice: 'Payment price',
  remove: 'Remove',
  youveBeenRemovedFromClaimBy: "You've been removed from claim by",
  signerSource: 'Signer source',
  enabled: 'Enabled',
  disabled: 'Disabled',
  enable: 'Enable',
  disable: 'Disable',
  signature: 'Signature',
  routingOrder: 'Routing order',
  invoices: 'Invoices',
  repairs: 'Repairs',
  inServiceDate: 'In service date',
  vehicleOriginalInServiceDate: 'Vehicle original in service date',
  mfgWholeVehicleWarranty: 'Whole vehicle warranty',
  mfgPowertrainWarranty: 'Powertrain warranty',
  mfgWholeVehicleWarrantyMonths: 'Whole vehicle warranty months',
  mfgPowertrainWarrantyMonths: 'Powertrain warranty months',
  mfgWholeVehicleWarrantyKm: 'Whole vehicle warranty km',
  mfgPowertrainWarrantyKm: 'Powertrain warranty km',
  warrantyOptions: 'Warranty options',
  options: 'Options',
  canSellPostSale: 'Can Sell Post-Sale',
  markContractAsPostSale: 'Mark contract as Post-Sale',
  isWarrantyDetailsVisible: 'Show Warranty Details',
  isPurchaseDetailsVisible: 'Show Purchase Details',
  coverageKm: 'Coverage Km',
  isPartOfPackage: 'Is part of package',
  isCustomerConsentRequired: 'Is customer consent required',
  part: 'Part',
  create: 'Create',
  areDocumentsAutomaticallySentForSigning: 'Documents are automatically sent for signing',
  jobDetails: 'Job details',
  hours: 'Hours',
  emails: 'Emails',
  sendDate: 'Send date',
  sendEmail: 'Send email',
  sendConsent: 'Send consent',
  consent: 'Consent',
  limitOfLiabilityAggregate: 'Limit of liability aggregate',
  consentRequired: 'Consent required',
  thankYouForYourResponse: 'Thank you for your response',
  youAlreadySubmittedYourResponseForThisConsent: 'You have already submitted a response for this consent!',
  yourConsentIsProcessedForContractAppNumber: 'Your consent is successfully {{status}}, for contract: {{appNumber}}!',
  yourConsentIsProcessed: 'Your consent is successfully {{status}}, for contract!',
  resendConsent: 'Resend consent',
  mustBeEmailDomain: 'Email domain must be {domain}',
  emailHistory: 'Email history',
  archive: 'Archive',
  unarchive: 'Unarchive',
  withArchived: 'With archived',
  emailSuccessfullySent: 'Email was successfully sent',
  unableToSendEmail: 'We are unable to send email at this time',
  sendResetPasswordEmail: 'Send reset password email',
  public: 'Public',
  exempt: 'Exempt',
  chooseExistingRepairFacility: 'Choose existing Repair Facility',
  //Product type
  'WARRANTY_TIER-BASED': 'Warranty - Tier based pricing',
  'WARRANTY_CLASS-BASED': 'Warranty - Class based pricing',
  'NON-WARRANTY': 'Non warranty pricing',
  CUSTOM: 'Custom pricing',

  //Permissions
  IMPORT_VEHICLEMAKE_FROM_EXCEL: 'Import vehicle make from excel',
  IMPORT_LOSSCODE_FROM_EXCEL: 'Import loss code from excel',
  CREATE_ROLE: 'Create role',
  EDIT_ROLE: 'Edit role',
  DELETE_ROLE: 'Delete role',
  UPLOAD_DOCUMENTS_CONTRACT: 'Upload contract document',
  CREATE_CONTRACT: 'Create contract',
  EDIT_CONTRACT: 'Edit contract',
  CREATE_EMAILTEMPLATE: 'Create email template',
  EDIT_EMAILTEMPLATE: 'Edit email template',
  DELETE_EMAIL_TEMPLATE: 'Delete email template',
  DELETE_CONTRACT: 'Delete contract',
  VIEW_PERMISSION: 'View permission',
  VIEW_CATEGORY: 'View category',
  VIEW_APPLICATION: 'View application',
  CREATE_USER: 'Create user',
  EDIT_USER: 'Edit user',
  DELETE_USER: 'Delete user',
  RESET_PASSWORD: 'Reset password',
  VIEW_ACCESSLOGS: 'View access logs',
  VIEW_AUDITLOGS: 'View change logs',
  CREATE_CLAIM: 'Create claim',
  CLAIM_ADMIN: 'Claim admin',
  EDIT_CLAIM: 'Edit claim',
  DELETE_CLAIM: 'Delete claim',
  CREATE_CLAIMTYPE: 'Create claim type',
  EDIT_CLAIMTYPE: 'Edit claim type',
  DELETE_CLAIMTYPE: 'Delete claim type',
  CREATE_LOSSCODE: 'Create loss code',
  EDIT_LOSSCODE: 'Edit loss code',
  DELETE_LOSSCODE: 'Delete loss code',
  CREATE_PRODUCT: 'Create product',
  EDIT_PRODUCT: 'Edit product',
  DELETE_PRODUCT: 'Delete product',
  EXTERNAL_COMMENT: 'External comments',
  INTERNAL_COMMENT: 'Internal comments',
  DELETE_PAYEE: 'Delete payee',
  EDIT_PAYEE: 'Edit payee',
  CREATE_PAYEE: 'Create payee',
  CREATE_PRICINGLIMITTEMPLATE: 'View pricing limit template',
  EDIT_PRICINGLIMITTEMPLATE: 'Edit pricing limit template',
  DELETE_PRICINGLIMITTEMPLATE: 'Delete pricing limit template',
  CREATE_PRICINGPLAN: 'View pricing plan',
  EDIT_PRICINGPLAN: 'Edit pricing plan',
  DELETE_PRICINGPLAN: 'Delete pricing plan',
  PRICINGLIMITS_DEALER: 'Edit pricing limits for dealer',

  //Dates
  dayOfWeek: 'Day of week',
  timeAndPlace: 'Time and place',
  date: 'Date',
  time: 'Time',
  dateAndTime: 'Date and time',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  sun: 'Sun',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Aug',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',
};
export default en;
