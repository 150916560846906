import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import Card from 'assets/components/card/Card';
import ThemeCardRow from 'assets/components/card/themes/Theme.Card.Row';
import Icon from 'assets/components/icon/Icon';
import MultilineTextInput from 'assets/components/inputs/multilineText/MultilineTextInput';
import SelectInput from 'assets/components/inputs/select/SelectInput';
import TextInput from 'assets/components/inputs/text/TextInput';
import FormWrapper from 'assets/components/inputs/wrappers/FormWrapper';
import InputWrapper from 'assets/components/inputs/wrappers/InputWrapper';
import Page from 'assets/components/page/Page';
import ThemePageStatic2 from 'assets/components/page/themes/Theme.Page.Static.2';
import { getLocales } from 'assets/locales/Locale';
import { ascLogoWhite } from 'assets/themes/assets/Images';
import Theme from 'assets/themes/Theme.Common';
import { formatFormPhone } from 'assets/utils/forms/formatFormPhone';
import { keys } from 'lodash';
import Client from 'models/claims/client/Model.Client';
import { ClaimCreatorOptions } from 'models/enums/ClaimCreation';
import ClaimContractSearchForm from 'module/claims/views/claim/other/Claim.ContractSearchForm';
import ClaimDocumentPreview from 'module/claims/views/claim/other/Claim.DocumentPreview';
import ClaimDocumentForm from 'module/public-web/views/claimSubmit/Claim.DocumentForm';
import usePublicClaimSubmit, { PublicClaimSubmitActions } from './Claim.Submit.Hooks';
import { PublicClaimSubmitRepairFacilityForm } from './Claim.Submit.RepairFacility.Form';

export const publicClaimSubmitIndexRoute = '/public/claim/submit';
export default function PublicClaimSubmitIndex() {
  const { lang } = getLocales();
  const {
    form,
    dealerListPublicApi,
    payeeListApi,
    claimTypesListPublicApi,
    onlyClaimType,
    claimTypeValue,
    action,
    setAction,
  } = usePublicClaimSubmit();

  return (
    <Page
      class={ThemePageStatic2}
      isLoading={claimTypesListPublicApi.isExecuting || dealerListPublicApi.isExecuting || payeeListApi.isExecuting}
      header={
        <>
          <Card
            class={ThemeCardRow}
            media={<img style={{ objectFit: 'contain' }} src={ascLogoWhite} />}
            style={{
              card: { flexDirection: 'column' },
              cardMedia: { width: '400px', objectFit: 'contain' },
              cardInfoTitle: { color: Theme.Color.highlightLight },
            }}
            title={lang.submitANewClaim}
            subtitle={lang.searchContractEnteringVinAndContractNumberOrClientLastName}
          />
        </>
      }
      render={
        <>
          {form.data.claim?.contract || form.data.claim?.contractId ? (
            <>
              <FormWrapper>
                <h2>
                  <Card
                    class={ThemeCardRow}
                    style={{ padding: 0 }}
                    title={
                      <>
                        {lang.contractNumber}: {form.data?.claim?.contract?.appNumber}
                      </>
                    }
                    subtitle={
                      <>
                        {lang.clientName}: {new Client(form.data?.claim?.contract?.client)?.displayInfo.title}
                      </>
                    }
                    info={
                      <>
                        {lang.vin}: {form.data?.claim?.contract?.vehicle?.vin}
                      </>
                    }
                  />
                </h2>
                <hr style={{ width: Theme.Size.fill }} />
                <h2 style={{ display: 'flex', alignItems: 'center' }}>{lang.details}</h2>
                {!onlyClaimType && (
                  <SelectInput
                    name="claimTypeId"
                    label={lang.claimType}
                    data={claimTypesListPublicApi.payload?.data?.map((it) => it.displayInfo)}
                    value={claimTypeValue}
                    onChange={form.update}
                    icon={<Icon class="fas-clipboard-list" />}
                  />
                )}
                <MultilineTextInput
                  name="customerComplaint"
                  label={lang.customerComplaint}
                  value={form.data?.customerComplaint}
                  onChange={form.update}
                />
                <MultilineTextInput
                  name="cause"
                  label={lang.causeOfFailure}
                  value={form.data?.cause}
                  onChange={form.update}
                />
                <MultilineTextInput
                  name="correction"
                  label={lang.correctionDetails}
                  value={form.data?.correction}
                  onChange={form.update}
                />
                <SelectInput
                  name="claimCreationOption"
                  label={lang.createdBy}
                  data={keys(ClaimCreatorOptions).map((option) => ({
                    id: option,
                    title: option.replace(/_/g, ' '),
                  }))}
                  value={form.data?.claimCreationOption}
                  error={form.errors.claimCreationOption}
                  onChange={form.update}
                />
                {ClaimCreatorOptions.REPAIR_FACILITY === form.data?.claimCreationOption && (
                  <>
                    {!action && (
                      <SelectInput
                        name="payeeId"
                        label={lang.repairFacility}
                        data={payeeListApi.payload?.data?.map((it) => it.displayInfo)}
                        value={form.data?.claim?.createdByPayee?.id}
                        error={form.errors?.claim?.createdByPayee?.id}
                        onAdd={() => {
                          setAction(PublicClaimSubmitActions.CREATE_REPAIR_FACILITY);
                          form.update({
                            claim: { ...form.data.claim, createdByPayee: undefined },
                          });
                        }}
                        onChange={({ payeeId }) =>
                          form.merge({
                            claim: { createdByPayee: payeeListApi.payload?.data?.find((it) => it.id === payeeId) },
                          })
                        }
                      />
                    )}
                    {action === PublicClaimSubmitActions.CREATE_REPAIR_FACILITY && (
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
                          <h2>
                            {lang.create} {lang.repairFacility}
                          </h2>
                          <Button
                            class={ThemeButtonCircle}
                            render={lang.cancel}
                            media={<Icon class="fas-ban" />}
                            onClick={() => {
                              setAction(undefined);
                              form.update({
                                claim: { ...form.data.claim, createdByPayee: undefined },
                              });
                            }}
                            htmlElementProps={{ title: lang.chooseExistingRepairFacility }}
                          />
                        </div>
                        <PublicClaimSubmitRepairFacilityForm form={form} />
                      </div>
                    )}
                  </>
                )}
                {ClaimCreatorOptions.DEALERSHIP === form.data?.claimCreationOption && (
                  <SelectInput
                    name="dealerId"
                    label={lang.dealership}
                    data={dealerListPublicApi.payload?.data?.map((it) => it.displayInfo)}
                    value={form.data?.claim?.dealerId}
                    error={form.errors?.claim?.dealerId}
                    onChange={({ dealerId }) => form.merge({ claim: { dealerId } })}
                  />
                )}
                <h2 style={{ display: 'flex', alignItems: 'center' }}>{`${lang.contact} ${lang.info}`}</h2>
                <InputWrapper>
                  <TextInput
                    label={lang.contactName}
                    name="publicContactName"
                    value={form.data.claim?.publicContactName}
                    error={form.errors?.claim?.publicContactName}
                    onChange={({ publicContactName }) => form.merge({ claim: { publicContactName } })}
                  />
                  <TextInput
                    label={lang.phoneNumber}
                    name="publicContactPhone"
                    value={form.data.claim?.publicContactPhone}
                    error={form.errors?.claim?.publicContactPhone}
                    onChange={({ publicContactPhone }) =>
                      form.merge({
                        claim: {
                          publicContactPhone: formatFormPhone(form.data.claim?.publicContactPhone, publicContactPhone),
                        },
                      })
                    }
                  />
                  <TextInput
                    label={lang.email}
                    name="publicContactEmail"
                    value={form.data.claim?.publicContactEmail}
                    error={form.errors?.claim?.publicContactEmail}
                    onChange={({ publicContactEmail }) => form.merge({ claim: { publicContactEmail } })}
                  />
                </InputWrapper>
                <h2 style={{ display: 'flex', alignItems: 'center' }}>
                  {lang.documents}
                  <Button
                    class={ThemeButtonCircle}
                    style={{ marginLeft: 'auto', fontSize: Theme.FontSize.M }}
                    render={form.data.isAddingNewDocument ? lang.cancel : lang.add}
                    media={<Icon class={form.data.isAddingNewDocument ? 'fas-ban' : 'fas-plus'} />}
                    data={{ isAddingNewDocument: !form.data.isAddingNewDocument }}
                    onClick={form.update}
                    active={form.data.isAddingNewDocument}
                    htmlElementProps={{ title: form.data.isAddingNewDocument ? lang.ban : lang.add }}
                  />
                </h2>
                <ClaimDocumentPreview
                  documents={[
                    ...form.data.claimDocuments.map((it) => ({
                      id: it.id,
                      url: it.mediaFile?.url,
                      name: it.name,
                      extension: it.mediaFile?.extension,
                      description: it.notes,
                      info: lang.new,
                    })),
                  ]}
                  isDisabled={false}
                />
                <br />
                {form.data.isAddingNewDocument && (
                  <ClaimDocumentForm
                    onSubmit={(document) => form.update({ claimDocuments: [...form.data.claimDocuments, document] })}
                  />
                )}
              </FormWrapper>
            </>
          ) : (
            <FormWrapper>
              <ClaimContractSearchForm onSubmit={(contract) => form.merge({ claim: { contract } })} />
            </FormWrapper>
          )}
        </>
      }
      footer={
        <>
          <Button
            onClick={form.submit}
            media={<Icon class="fas-save" />}
            render={
              <>
                {lang.save}
                {form.data.claimDocuments.length > 0 && (
                  <>
                    &nbsp;+&nbsp;{form.data.claimDocuments.length}
                    &nbsp;
                    <Icon class="fas-paperclip" />
                  </>
                )}
              </>
            }
            active={!form.hasErrors && form.isUpdated}
            disabled={form.hasErrors || !form.isUpdated}
            isLoading={form.isSubmitted}
            class={ThemeButtonCircle}
            htmlElementProps={{ title: lang.save }}
          />
        </>
      }
    />
  );
}
