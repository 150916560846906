import Button from 'assets/components/button/Button';
import ThemeButtonCircle from 'assets/components/button/themes/Theme.Button.Circle';
import DataMap from 'assets/components/dataMap/DataMap';
import Confirm from 'assets/components/page/templates/Confirm';
import ThemeConfirmDefault from 'assets/components/page/templates/themes/Theme.Confirm.Default';
import ThemeTableSimple from 'assets/components/table/themes/Theme.Table.Simple';
import { getLocales } from 'assets/locales/Locale';
import Theme from 'assets/themes/Theme.Common';
import { date, defaultDateTimeFormat, defaultServerDateTimeFormat } from 'assets/utils/data/Date';
import { combineStrings } from 'assets/utils/data/String';
import { useClaimEdit } from '../ClaimEdit.Hooks';
import ErrorWrapper from 'assets/components/inputs/wrappers/ErrorWrapper';
import { getClaimContact } from 'assets/utils/other/Utils.Other.ClaimEdit';
import useClaimRedirect from 'module/claims/hooks/useClaimRedirect';

export default function ClaimEditClaimHistory() {
  const { lang } = getLocales();
  const { contract, product, claimHistory, emailTemplates, statusInfo, navigateToTab } = useClaimEdit();
  const { redirectAndTakeOwnership, redirectInfo, setRedirectInfo } = useClaimRedirect({
    emailTemplates,
    statusInfo,
    navigateToTab,
  });

  return (
    <>
      {redirectInfo?.claim && !redirectInfo?.claim?.owner && (
        <Confirm
          onConfirm={() => redirectAndTakeOwnership(redirectInfo)}
          onClose={() => setRedirectInfo(null)}
          actionName={lang.takeOwnershipOfTheClaim}
          itemName={redirectInfo?.claim?.id?.toString()}
          confirmationType="nextButton"
          subcomponents={ThemeConfirmDefault}
        />
      )}
      <div style={{ margin: Theme.Size.XL, marginBottom: 0 }}>
        <h2 style={{ marginTop: 0 }}>Claims history</h2>
        {!claimHistory?.length && <ErrorWrapper children={lang.noInfo} />}
      </div>
      {!!claimHistory?.length && (
        <table className={ThemeTableSimple}>
          <thead>
            <tr>
              <th>{lang.claim}</th>
              <th>{lang.info}</th>
              <th>{lang.decidedDate}</th>
              <th>{lang.paymentDate}</th>
              <th>{lang.createdAt}</th>
              <th>{lang.lastModified}</th>
              <th>{lang.status}</th>
              <th>{lang.owner}</th>
              <th>{lang.actions}</th>
            </tr>
          </thead>
          <tbody>
            {!claimHistory?.length && (
              <tr>
                <td colSpan={7}>{lang.noInfo}</td>
              </tr>
            )}
            <DataMap
              data={claimHistory}
              render={({ data: claim }) => (
                <tr key={claim.id}>
                  <td>
                    <b>{combineStrings('-', product?.category?.code, claim.id.toString())}</b>
                    <br />
                    {product?.name ?? lang.unknownProduct}
                  </td>
                  <td>
                    {lang.contact}: <b>{getClaimContact(claim)}</b>
                    <br />
                    {lang.client}:{' '}
                    <b>{combineStrings(' ', contract?.client?.firstName, contract?.client?.lastName) ?? 'N/A'}</b>
                  </td>
                  <td>
                    {claim?.decidedDateUtc
                      ? date(claim?.decidedDateUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat)
                      : 'N/A'}
                  </td>
                  <td>
                    {claim?.paymentDateUtc
                      ? date(claim?.paymentDateUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat)
                      : 'N/A'}
                  </td>
                  <td>{date(claim?.createdAtUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat)}</td>
                  <td>{date(claim?.modifiedAtUtc, defaultServerDateTimeFormat).format(defaultDateTimeFormat)}</td>
                  <td>
                    <b>{claim.statusCode.replace(/_/g, ' - ') || lang.noStatusDefined}</b>
                  </td>
                  <td>{claim.owner ? `${claim.owner?.firstName} ${claim.owner?.lastName}` : 'N / A'}</td>
                  <td>
                    <Button
                      onClick={redirectAndTakeOwnership}
                      data={{ claim }}
                      class={ThemeButtonCircle}
                      media="fas-folder-open"
                      htmlElementProps={{
                        title: `${lang.open} ${lang.claim}`,
                      }}
                      render={lang.open}
                    />
                  </td>
                </tr>
              )}
            />
          </tbody>
        </table>
      )}
    </>
  );
}
